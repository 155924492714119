import LoadingAnimation from "components/LoadingAnimation";
import { QUERIES } from "../constants";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useQueryClient } from "react-query";
import { pageLinks } from "utils/links";

const Home: NextPage = () => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const currentUser = queryClient.getQueryData(QUERIES.LOAD_CURRENT_USER);
  const cognitoUser = queryClient.getQueryData(
    QUERIES.COGNITO_CURRENT_AUTHENTICATED_USER
  );

  if (currentUser && cognitoUser) {
    router.replace(pageLinks.Profile.url);
  } else {
    router.replace(pageLinks.Login.url);
  }
  return <LoadingAnimation />;
};

export default Home;
